* {
  font-family: Source Sans Pro, sans-serif;
}

h3 {
  margin: 20px;
  font-size: 1.4rem;
  font-weight: 600;
}

body {
  width: 600px;
  height: 800px;
  background-color: #c8cedf;
  flex-direction: column;
  margin: 0;
  display: flex;
}

header {
  height: 70px;
  background-color: #fff;
  padding-top: 30px;
  position: relative;
}

header img {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

footer {
  width: 100%;
  height: 60px;
  background-color: #c8cedf;
  padding-top: 10px;
}

footer p {
  color: #2b3688;
  text-align: center;
  margin: 0 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.default {
  width: 250px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.baseline {
  color: #2b3688;
  margin: 13px 15px;
  font-size: 10px;
}

.location-info {
  text-align: right;
  flex-direction: column;
  margin-right: 10px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.location-text {
  color: #78be20;
  margin-bottom: 0;
  font-size: 12px;
}

.location-icon {
  margin-left: 5px;
}

.aire-text {
  color: #2b3688;
}

.main-container {
  height: fit-content;
  background-color: #2b3688;
  flex-grow: 1;
}

.bodyContent {
  color: #fff;
  margin: 0 30px;
}

.block-title {
  align-items: center;
  display: flex;
}

.lng-img {
  width: 30px;
}

.lang-text {
  text-align: justify-all;
  margin-top: -15px;
  margin-left: 15px;
  font-size: 16px;
}

.bottom-grid {
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
}

.availability-container {
  max-width: 130px;
  align-items: center;
  display: flex;
}

.availability-content {
  width: 130px;
  height: 110px;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
}

.location-name {
  margin: 15px 0 0;
  font-size: 14px;
  font-weight: bold;
}

.card-img {
  width: 55px;
  height: 55px;
  background-color: #2b3688;
  border: 4px solid #2b3688;
  border-radius: 100px;
  margin-top: -32px;
}

.availability-text {
  color: #2b3688;
  margin-top: 2px;
  margin-bottom: 3px;
  font-size: 16px;
}

.availability-counter {
  color: #78be20;
  margin: 0 5px 10px;
  font-size: 30px;
}

.right-column {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.centered-text {
  text-align: center;
  height: 100%;
  justify-content: center;
  display: flex;
}

.centered-text p {
  margin: 10px 0;
}

.badge-container {
  justify-content: center;
  margin-top: 10px;
  display: flex;
}

.badge-container img {
  width: 120px;
  margin: 0 10px;
}

.bold {
  font-weight: bold;
}

.lang-text p {
  text-align: justify;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  font-size: 1.05rem;
  line-height: 1.3rem;
}

.lang-text p .bold {
  font-size: 1.2rem;
  font-weight: 700;
}

/*# sourceMappingURL=index.7343d595.css.map */
