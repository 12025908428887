* {
  font-family: 'Source Sans Pro', sans-serif;}

h3 {
  margin: 20px;
  font-weight: 600;
  font-size: 1.4rem;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 600px;
  height: 800px;
  background-color: #c8cedf;

}

header {
  padding-top: 30px;
  background-color: #fff;
  position: relative;
  height: 70px;
}

header img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
}

footer {
  background-color: #c8cedf;
  width: 100%;
  height:60px;
  padding-top: 10px;
}

footer p {
  color: #2B3688;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  margin: 0px 20px;
}

.default {
  width: 250px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.baseline {
  margin: 13px 15px;
  color: #2b3688;
  font-size: 10px;
}

.location-info {
  display: flex;
  text-align: right;
  flex-direction: column;
  position: absolute;
  margin-right: 10px;
  top: 0;
  right: 0;
}

.location-text {
  font-size: 12px;
  color:#78BE20;
  margin-bottom: 0px;
}

.location-icon {
  margin-left: 5px;
}

.aire-text {
  color:#2B3688;
}

.main-container {
  background-color: #2B3688;
  height: fit-content;
  flex-grow: 1;
}

.bodyContent {
  margin: 0px 30px;
  color: white;
}

.block-title {
  display: flex;
  align-items: center;
}

.lng-img {
  width: 30px;
}

.lang-text {
  margin-top: -15px;
  margin-left: 15px;
  text-align: justify-all;
  font-size: 16px;
}

.bottom-grid {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
}

.availability-container {
  max-width: 130px;
  display: flex;
  align-items: center;
}

.availability-content {
  background-color: #ffffff;
  width: 130px;
  height: 110px;
  text-align: center;
  border-radius: 5px;
}

.location-name {
  font-weight: bold;
  font-size: 14px;
  margin: 15px 0 0;
}

.card-img {
  width: 55px;
  height: 55px;
  border: 4px solid #2B3688;
  border-radius: 100px;
  margin-top: -32px;
  background-color: #2B3688;
}

.availability-text {
  margin-top: 2px;
  margin-bottom: 3px;
  font-size: 16px;
  color: #2B3688;
}

.availability-counter {
  color: #78BE20;
  font-size: 30px;
  margin: 0 5px 10px;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centered-text {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.centered-text p{
  margin: 10px 0;
}

.badge-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.badge-container img {
  margin: 0 10px;
  width: 120px;
}

.bold {
  font-weight: bold;
}

.lang-text p {
  font-size: 1.05rem;
  line-height: 1.3rem;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: justify;
}

.lang-text p .bold {
  font-size: 1.2rem;
  font-weight: 700;
}